<template>
  <locale-router-link
    :to="`recipes/${item.id}`"
    tag="div"
    v-if="item"
    class="any-taste__item"
  >
    <img v-lazy="getImage(item)" alt="Recipe image" />

    <div v-if="item.attributes.title" class="any-taste__title">
      {{ item.attributes.title }}
    </div>

    <div class="any-taste__info">
      <div class="any-taste__info-wrapper">
        <div class="any-taste__time">
          <div class="any-taste__time-title">{{ $t("willReady") }}</div>
          <div class="any-taste__time-amount" v-if="item.attributes.ETA">
            {{ item.attributes.ETA }}
          </div>
        </div>

        <recipe-difficulty
          v-if="item.attributes.difficulty"
          :difficultyNumber="item.attributes.difficulty"
        />
      </div>
      <!-- Any taste Author -->
      <div v-if="item.attributes.author.data" class="any-taste__author">
        <img
          :src="getAutorImage(item)"
          alt="author image"
          class="any-taste__author-img"
        />
        <div class="any-taste__author-info">
          <!-- <div class="any-taste__author-title">Автор рецепта:</div> -->
          <div class="any-taste__author-name">
            {{ item.attributes.author.data.attributes.name }}
          </div>
          <!-- <div class="any-taste__author-position">
            {{ item.attributes.author.data.attributes.whois }}
          </div> -->
        </div>
      </div>
    </div>
  </locale-router-link>
</template>

<script>
export default {
  name: "RecipeRecommendationItem",
  components: {
    RecipeDifficulty: () => import("@/components/recipe/RecipeDifficulty"),
  },
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  methods: {
    getAutorImage(item) {
      const urlImg =
        item?.attributes?.author?.data?.attributes?.avatar?.data?.attributes
          ?.url;
      return this.$helpers.getAbsolutePath(urlImg);
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image?.data?.attributes?.url
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
